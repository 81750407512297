import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/font-awesome.min.css';
import '../css/app.css';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';

var baseURL = "https://ddminigames.co.in/";

var app = new Framework7({
  root: '#app', // App root element
  id: 'app.dualdragon.com', // App bundle ID
  name: 'Dual Dragon', // App name
  theme: 'auto', // Automatic theme detection
  view: {
    // pushState: true,
    // pushStateSeparator: '#!',
    // pushStateOnLoad: true,
  },
  //app pushstate
  smartSelect: {
    closeOnSelect: true,
  },
  sheet: {
    closeByBackdropClick: false
  },
  // App root methods
  methods: {
    initImageUpload: function(box) {
      let uploadField = box.querySelector('.image-upload');
        
        uploadField.addEventListener('change', getFile);
      
        function getFile(e){
          let file = e.currentTarget.files[0];
          checkType(file);
        }
        
        function previewImage(file){
          let thumb = box.querySelector('.js--image-preview'),
              reader = new FileReader();
      
          reader.onload = function() {
            thumb.style.backgroundImage = 'url(' + reader.result + ')';
          }
          reader.readAsDataURL(file);
          thumb.className += ' js--no-default';
        }
      
        function checkType(file){
          let imageType = /image.*/;
          //var size=(file.size)/1000;
          if (!file.type.match(imageType)) {
            app.toast.show({
              text: 'This is not an Image!',
              closeTimeout: 3000,
            });
            throw 'This is not an image';
          } else if (!file){
            app.toast.show({
              text: 'No file found!',
              closeTimeout: 3000,
            });
            throw 'No file found';
          } else {
            previewImage(file);
          }
        }
    },
    initDropEffect: function(box) {
      let area, drop, areaWidth, areaHeight, maxDistance, dropWidth, dropHeight, x, y;
          
      // get clickable area for drop effect
      area = box.querySelector('.js--image-preview');
      area.addEventListener('click', fireRipple);
      
      function fireRipple(e){
        area = e.currentTarget
        // create drop
        if(!drop){
          drop = document.createElement('span');
          drop.className = 'drop';
          this.appendChild(drop);
        }
        // reset animate class
        drop.className = 'drop';
        
        // calculate dimensions of area (longest side)
        areaWidth = getComputedStyle(this, null).getPropertyValue("width");
        areaHeight = getComputedStyle(this, null).getPropertyValue("height");
        maxDistance = Math.max(parseInt(areaWidth, 10), parseInt(areaHeight, 10));
    
        // set drop dimensions to fill area
        drop.style.width = maxDistance + 'px';
        drop.style.height = maxDistance + 'px';
        
        // calculate dimensions of drop
        dropWidth = getComputedStyle(this, null).getPropertyValue("width");
        dropHeight = getComputedStyle(this, null).getPropertyValue("height");
        
        // calculate relative coordinates of click
        // logic: click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center
        x = e.pageX - this.offsetLeft - (parseInt(dropWidth, 10)/2);
        y = e.pageY - this.offsetTop - (parseInt(dropHeight, 10)/2) - 30;
        
        // position drop and animate
        drop.style.top = y + 'px';
        drop.style.left = x + 'px';
        drop.className += ' animate';
        e.stopPropagation();
        
      }
    },
    initFileUpload: function() {
      var inputs = document.querySelectorAll( '.inputfile' );
        Array.prototype.forEach.call( inputs, function( input )
        {
          var label	 = input.nextElementSibling,
            labelVal = label.innerHTML;

          input.addEventListener( 'change', function( e )
          {
            var fileName = '';
            if( this.files && this.files.length > 1 )
              fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            else
              fileName = e.target.value.split( '\\' ).pop();

            if( fileName )
              label.querySelector( 'span' ).innerHTML = fileName;
            else
              label.innerHTML = labelVal;
          });
        });
    },
    getPIN: function(pincode, success, error) {
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": "https://pincode.p.rapidapi.com/",
        "method": "POST",
        "headers": {
          "x-rapidapi-host": "pincode.p.rapidapi.com",
          "x-rapidapi-key": "d52c228c20msh28eb4e495fba18ep1836f6jsn44bce943016f",
          "content-type": "application/json",
          "accept": "application/json"
        },
        "processData": false,
        "data": "{\"searchBy\":\"pincode\",\"value\":"+pincode+"}"
      }
      
      $.ajax(settings).done(function (response) {
        if(response.length === 0) {
          error("no places found");
        }
        else {
          success(response);
        }
      });
    },
    getJSON: function( url, callback) {
      $.getJSON(url, function(data) {
        callback(data);
      });
    },
    request: function( url, data, datatype, beforesend, success, error) {
      $.ajax({
        url: url,
        data: data,
        cache: false,
        dataType: datatype,
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
          beforesend();
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    post: function( url, data, datatype, beforesend, success, error) {
      $.ajax({
        url: url,
        data: data,
        cache: false,
        dataType: datatype,
        type: 'POST',
        mimeType: "multipart/form-data",
        contentType: false,
        processData: false,
        crossDomain : true,
        beforeSend: function() {
          beforesend();
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getUserData: function(success, error) {
      $.ajax({
        url: baseURL+'app/php/ajaxAuthentication.php',
        data: {userinfo: true, token: localStorage.getItem('authToken')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
          $$('.reload-icon').addClass('rotate');
        },
        success: function(_response){
          $$('.reload-icon').removeClass('rotate');
          success(_response);
        },
        error: function(_error){
          $$('.reload-icon').removeClass('rotate');
        }
      });
    },
    getUserDataLoan: function(success, error) {
      $.ajax({
        url: baseURL+'app/php/ajaxAuthentication.php',
        data: {loaninfo: true, token: localStorage.getItem('authToken')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
          $$('.reload-icon').addClass('rotate');
        },
        success: function(_response){
          $$('.reload-icon').removeClass('rotate');
          success(_response);
        },
        error: function(_error){
          $$('.reload-icon').removeClass('rotate');
        }
      });
    },
    getBank: function(success, error) {
      $.ajax({
        url: baseURL+'app/php/ajaxAuthentication.php',
        data: {getbank: true},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {},
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getSettings: function(success, error) {
      $.ajax({
        url: baseURL+'app/php/ajaxAuthentication.php',
        data: {getSettings: true},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {},
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    saveAgreement: function(success, error) {
      var data = $$('#agreement-popup .page-content .block').html();
      $.ajax({
        url: baseURL+'app/php/saveAgreement.php',
        data: {token: localStorage.getItem('authToken')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    random_color: function() {
      var letters = '0123456789ABCDEF';
      var color = '';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    addComma: function(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    getMaxloan: function(income) {
      if(income <= 15000) {
        return 100000;
      }
      else if(income > 15000 && income <= 30000) {
        return 200000;
      }
      else if(income > 30000 && income <= 50000) {
        return 300000;
      }
      else if(income > 50000 && income <= 75000) {
        return 400000;
      }
      else if(income > 75000) {
        return 500000;
      }
    },
    getActiveAds: function() {
      app.methods.request(
        baseURL+'app/php/getAds.php',
        {},
        'json',
        function() {
          $$('.data-clb').empty();
        },
        function(data) {
          var ads_data = "";
          $.each(data, function(key, val){
            $$('.data-clb').empty();
            ads_data += '<li>\
            <div class="item-content">\
              <div class="item-media">\
                <img src="'+val.icon+'" style="width: 100px;">\
              </div>\
              <div class="item-inner">\
                <div class="item-title-row">\
                  <div class="item-title">'+val.name+'</div>\
                  <div class="item-after"><a href="/register/" data-webapp="'+val.webapp+'" class="button button-fill button-small button-round bg-color-green text-color-white text-align-center f-14 button-shine btn-ads" style="width: 100px;">Continue</a></div>\
                </div>\
                <div class="item-subtitle" style="margin-top: 15px;">RATE OF INTEREST: '+val.roi+'%</div>\
                <div class="item-text">PROCESSING FEES: '+val.pf+'%</div>\
                <div class="item-text">LOAN AMOUNT: '+val.amount+' LAKHS</div>\
                <div class="item-text">TENURE: '+val.tenure+' Months</div>\
              </div>\
            </div>\
          </li>';
          });
          $$('.data-clb').append(ads_data);
        },
        function() {
          $$('.data-clb').empty();
        }
      );
    }
  },
  // App routes
  routes: routes,



  // Input settings
  input: {
    scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    overlay: Framework7.device.cordova && Framework7.device.ios || 'auto',
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
      }
    },
    //APP PAGE INIT
    pageInit: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
      }
    },
  },
});
